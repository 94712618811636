import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"our-services"} />
      <Helmet>
        <title>Наші послуги | Star Yachts</title>
        <meta
          name={"description"}
          content={"З кожною подорожжю перевершуйте свої очікування"}
        />
        <meta property={"og:title"} content={"Наші послуги | Star Yachts"} />
        <meta
          property={"og:description"}
          content={"З кожною подорожжю перевершуйте свої очікування"}
        />
        <meta
          property={"og:image"}
          content={"https://cool.uvixenasell.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cool.uvixenasell.com/img/images.jpeg"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cool.uvixenasell.com/img/images.jpeg"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cool.uvixenasell.com/img/images.jpeg"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cool.uvixenasell.com/img/images.jpeg"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cool.uvixenasell.com/img/images.jpeg"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cool.uvixenasell.com/img/images.jpeg"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="90px 0 100px 0" quarkly-title="List-3">
        <Box display="flex" flex-direction="row" padding="0px 0px 50px 0px">
          <Box
            display="flex"
            width="40%"
            flex-direction="column"
            justify-content="center"
            align-items="flex-start"
            lg-align-items="center"
            lg-margin="0px 0px 20px 0px"
            sm-padding="0px 0px 0px 0px"
            padding="16px 16px 16px 0px"
            lg-width="100%"
            md-margin="0px 0px 20px 0px"
            sm-margin="0px 0px 30px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--dark"
              font="--headline1"
              lg-text-align="center"
              sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
              padding="0px 16px 0px 0px"
            >
              Наші послуги
            </Text>
          </Box>
          <Box
            display="flex"
            width="60%"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            lg-align-items="center"
            lg-margin="0px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            padding="28px 0px 16px 16px"
            lg-width="100%"
            md-margin="0px 0px 20px 0px"
            sm-margin="0px 0px 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL1"
              font="--lead"
              lg-text-align="center"
              display="flex"
              align-items="flex-start"
            >
              У Star Yachts ми прагнемо надати більше, ніж просто прогулянку на
              яхті. Наш комплексний набір послуг розроблений для того, щоб
              забезпечити безперебійну і збагачуючу морську подорож. Від
              індивідуальних маршрутів до ексклюзивних зручностей на борту - ми
              подбаємо про кожен аспект вашої морської подорожі. Відкрийте для
              себе різноманітність послуг, які ми пропонуємо, кожна з яких
              створена для того, щоб покращити ваш досвід на воді.
            </Text>
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="0 34px"
          md-grid-template-columns="1fr"
          md-grid-gap="36px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cool.uvixenasell.com/img/4.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Індивідуальні чартери
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Індивідуальні маршрути: Кожна поїздка ретельно планується
              відповідно до ваших уподобань, забезпечуючи персоналізований
              маршрут і зупинки.
              <br />
              <br />
              Святкування подій: Ідеально підходить для днів народження, ювілеїв
              або особливих подій, ми зробимо ваші знаменні дати незабутніми
              завдяки індивідуальним темам і декораціям.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cool.uvixenasell.com/img/5.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Екскурсії з гідом
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Морська освіта: Дізнайтеся про морське життя та місцеву екологію
              від досвідчених гідів.
              <br />
              <br />
              Історичні маршрути: Дослідіть історичні берегові лінії та
              дізнайтеся про минуле регіону під керівництвом
              експертів-істориків.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cool.uvixenasell.com/img/6.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Зручності на борту
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Харчування для гурманів: Насолоджуйтесь стравами, приготованими на
              борту досвідченими кухарями зі свіжих місцевих продуктів.
              <br />
              <br />
              Розкішний комфорт: Високоякісна постільна білизна, тераси для
              засмаги та зручні зони відпочинку для покращення релаксації.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="List-3">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="0 34px"
          md-grid-template-columns="1fr"
          md-grid-gap="36px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cool.uvixenasell.com/img/7.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Багатоденні екскурсії
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Ночівля: Яхти обладнані комфортабельними спальними приміщеннями
              для тривалого перебування.
              <br />
              <br />
              Подовжені маршрути: Досліджуйте далі і глибше з планами, які
              охоплюють кілька днів.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cool.uvixenasell.com/img/8.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Безпека та навчання
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Підготовка екіпажу: Всі члени екіпажу проходять ретельну
              підготовку з безпеки та гостинності.
              <br />
              <br />
              Техніка безпеки: Найсучасніші засоби безпеки та навігаційні
              технології на всіх яхтах.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cool.uvixenasell.com/img/9.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Сезонні пропозиції
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Позапікові пропозиції: Спеціальні тарифи та пакети в непікові
              сезони для оптимальної економії.
              <br />
              <br />
              Тематичні подорожі: Сезонні теми, які змінюють враження від
              подорожі на яхті, наприклад, осінні прибережні тури або весняні
              подорожі до морських мешканців.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
